@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.App {
  background-color: #db9dae;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.formHolder {
  width: 50%;
  text-align: left;
}
.noteData {
  background-color: rgb(255, 255, 255);
  color: #db9dae;
  padding: 15px;
  border-radius: 10px;
}

.note-body {
  white-space: pre-wrap;
}
.eachNote {
  background: white;
  color: #db9dae;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.notetitle {
  font-weight: bold;
  background-color: #db9dae;
  color: white;
  border-radius: 5px;
}

/* submit button */
button.btn-outline-primary {
  border-color: white;
  color: white;
}
button.btn-outline-primary:hover {
  background-color: white;
  border-color: #db9dae;
  color: #db9dae;
}
/* edit button */
button.btn-outline-info {
  border-color: rgb(239, 167, 179);
  color: rgb(239, 167, 179);
}
button.btn-outline-info:hover {
  background-color: #db9dae;
  border-color: white;
  color: white;
}
input {
  border-radius: 5px;
  border: 10px solid transparent;
  outline: none;
  padding: 0px;
  color: #db9dae;
}
input::placeholder,
textarea.note-body-input::placeholder {
  color: #db9dae;
}
textarea.note-body-input {
  border: none;
  color: #db9dae;
  border-color: 2px solid transparent;
}
textarea.note-body-input:not(.edit) {
  min-height: 200px !important;
}
/* min height isnt working bc lack of specificity textarea is buggy with css*/
textarea.note-body-input:focus {
  color: #db9dae;
  box-shadow: none;
  outline: none;
}
.notemakingarea {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}
#noteholder {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: start;
  align-content: stretch;
  width: 50%;
}
.notebuttons {
  display: flex;
}
